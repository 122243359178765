import cockpitClient from '@/App/http/cockpit-http-common'

class settingsServices {
  getAlertIndicatorsSettings(): Promise<any> {
    return cockpitClient.get('/AlertIndicatorsSettings')
  }
  putAlertIndicatorsSettings(data:any): Promise<any> {
    return cockpitClient.put('/AlertIndicatorsSettings', data)
  }
}
export default new settingsServices()
